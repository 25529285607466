import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "gatsby-plugin-react-i18next";
import classNames from "classnames";

import { ReactComponent as LogoSVG } from "../../../images/logo.svg";
import { ClassNameProps } from "../../../common/props";
import { IconName } from "../../core/icon/iconName";
import { Icon } from "../../core/icon/Icon";

// TODO: make the site responsive
export const DefaultLayout: React.FC = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <React.Fragment>
      <div className="font-body" style={{ minWidth: "1020px" }}>
        <Header/>
        {children}
        <Footer/>
      </div>
    </React.Fragment>
  );
};

const Logo: React.FC<ClassNameProps> = ({ className }: ClassNameProps) => {
  return (
    <span className={classNames(className, "flex")}>
      <span className="mr-3 w-10">
        <LogoSVG className="h-full w-full"/>
      </span>
      <span className="flex flex-shrink flex-col justify-center whitespace-no-wrap">
        <span className="text-center font-semibold text-gray-1 border-blue-3 border-b-2">
          Công ty Cổ phần Kết Cấu và Kiến Trúc Việt Nam
        </span>
        <span className="text-center text-sm text-gray-2 whitespace-no-wrap">
          Viet Nam Structure and Architecture Joint Stock Company
        </span>
      </span>
    </span>
  );
};

type NavLinkProps = ClassNameProps & {
  to: string;
};

const NavLink: React.FC<NavLinkProps> = ({
  to,
  className,
  children,
}: React.PropsWithChildren<NavLinkProps>) => {
  return (
    <Link
      to={to}
      className={classNames(className, "text-gray-3 whitespace-no-wrap transition-colors hover:text-blue-1 duration-300")}
    >
      {children}
    </Link>
  );
};

const ContactButton: React.FC<ClassNameProps> = ({ className }: ClassNameProps) => {
  const { t } = useTranslation();
  return (
    <Link
      to="/contact"
      className={classNames(className, "whitespace-no-wrap px-4 py-2 text-white bg-blue-3 border-2 border-blue-3 rounded shadow-lg transition duration-300 hover:bg-white hover:text-blue-3")}
    >
      {t("nav.contact")}
    </Link>
  );
};

const NavBar: React.FC<ClassNameProps> = ({ className }: ClassNameProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(className, "flex flex-initial items-center font-medium")}
    >
      <NavLink to="/" className="p-3 flex-grow">{t("nav.home")}</NavLink>
      <NavLink to="/about" className="p-3">{t("nav.about")}</NavLink>
      <NavLink to="/projects" className="p-3">{t("nav.projects")}</NavLink>
      <ContactButton className="ml-3"/>
    </div>
  );
};

const Header: React.FC = () => {
  return (
    <header className="relative z-20 shadow">
      <div className="container mx-auto flex justify-between h-24 py-4">
        <Link to="/">
          <Logo/>
        </Link>
        <NavBar/>
      </div>
    </header>
  );
};

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-6 p-3 text-gray-1">
      <div className="container mx-auto py-6">
        <div className="lg:flex flex-row hidden">
          <div className="mr-16">
            <h6 className="uppercase text-gray-4 font-semibold">Dự án</h6>
            <Link to="/projects">{t("nav.projects")}</Link>
          </div>
          <div className="mr-16">
            <h6 className="uppercase text-gray-4 font-semibold">Công ty</h6>
            <Link to="/about">{t("nav.about")}</Link>
          </div>
        </div>
        <div className="flex flex-col lg:items-end items-center w-full lg:w-auto">
          <Link
            className="border px-4 py-2 border-gray-1 rounded hover:text-gray-1 mb-3"
            to="/projects"
          >
            {t("nav.contact")}
          </Link>
          <a href="tel:84-983-120-599" className="flex items-center mb-3 text-blue-3 hover:underline">
            <Icon className="h-4 w-4 mr-2 inline" icon={IconName.Phone}/>
            <span>0983 120 599</span>
          </a>
          <a href="mailto:vsj.infor@gmail.com" className="flex items-center mb-3 text-blue-3 hover:underline">
            <Icon className="h-4 w-4 mr-2 inline" icon={IconName.Envelop}/>
            <span>vsj.infor@gmail.com</span>
          </a>
          <div className="lg:text-right text-center mb-3">
            1907 Sunshine Palace<br/>
            Đường 13 Lĩnh Nam, Mai Động, Hoàng Mai, Hà Nội
          </div>
        </div>
      </div>
    </div>
  );
};
