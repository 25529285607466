import React from "react";

import {IconName} from "./iconName";
import {ICON_PATHS} from "./iconPath";

export type IconProps = {
  icon: IconName;
  className?: string;
};

export function Icon({icon, className}: IconProps): React.ReactElement {
  const paths = ICON_PATHS[icon].map(
    (d, i) => <path d={d} fill="currentColor" fillRule="evenodd" key={i}/>,
  );
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 20 20">
      {paths}
    </svg>
  )
}
